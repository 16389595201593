<template>
  <div>
    <div class="row align-items-center mb-4">
      <div class="col-md-6">
        <h2 class="text-dark d-inline-block m-0">All Earnings</h2>
      </div>
      <div class="col-md-6"></div>
    </div>
    <div>
      <SectionLoading v-if="loading" text="Earnings data loading..." />
      <div v-else>
        <div
          v-if="contents && contents.data && contents.data.length == 0"
          class="text-center my-5"
        >
          <p class="text-dark mt-5 pt-5">No Earnings data available now...</p>
        </div>
        <div v-else>
          <div class="card siteCard mt-3">
            <div class="p-3">
              <div class="">
                <p class="creatorTitle">Total Earnings</p>
                <h3 class="my-2">
                  <span class="text-dark font-weight-bold">{{
                    earningsData.total | currency("€", 2)
                  }}</span>
                </h3>
              </div>
              <div class="row">
                <div
                  class="col-md-2"
                  v-for="(earning, index) in earningsData.earning"
                  :key="index"
                >
                  <p class="creatorTitle">{{ earning.name }}</p>
                  <h3 class="my-2">
                    <span class="text-dark font-weight-bold">{{
                      earning.revenue | currency("€", 2)
                    }}</span>
                  </h3>
                </div>
              </div>

              <div>
                <v-chart class="trackChart" :option="trackChart" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { PlusIcon } from "vue-feather-icons";
import SectionLoading from "../../../components/SectionLoading";
export default {
  name: "Home",
  components: {
    PlusIcon,
    SectionLoading,
  },
  data() {
    return {
      loading: false,
      contents: [],
      activeTitle: "dsp",
      list: "tile",
      earningsData: {},
      listStyles: [
        { text: "Tile View", value: "tile" },
        { text: "List View", value: "list" },
      ],
      table_options: {
        current_page: 1,
      },
      trackChart: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {},
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: "category",
          data: [],
        },
        series: [
          {
            name: "Revenue",
            type: "bar",
            color: "#f88f80",
            data: [],
          },
          {
            name: "Streams",
            type: "bar",
            color: "#f8c9a0",
            data: [],
          },
          {
            name: "Download",
            type: "bar",
            color: "#f8f0a0",
            data: [],
          },
        ],
      },
    };
  },
  watch: {
    "table_options.current_page": function () {
      this.getContents(true);
    },
  },
  mounted() {
    this.getBl();
  },
  methods: {
    getBl() {
      this.getContents(false);
    },
    getContents(divLoad) {
      if (divLoad) {
        this.$vs.loading({
          container: "#div-with-loading",
          scale: 0.6,
        });
      } else {
        this.loading = true;
      }
      let fetch = {
        path: `/admin/earnings?size=20&type=${this.activeTitle}`,
      };
      this.$store
        .dispatch("getRequest", fetch)
        .then((resp) => {
          this.earningsData = resp.data.data || {};

          for (let i = 0; i < this.earningsData.earning.length; i++) {
            this.trackChart.yAxis.data.push(this.earningsData.earning[i].name);
            this.trackChart.series[0].data.push(
              this.earningsData.earning[i].revenue
            );

            this.trackChart.series[1].data.push(
              this.earningsData.earning[i].streams
            );
            this.trackChart.series[2].data.push(
              this.earningsData.earning[i].downloads
            );
            // this.trackChart.series[2].data.push(
            //   this.earningsData.earning[i].downloads
            // );
          }

          if (divLoad) {
            this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          }
          this.loading = false;
        })
        .catch((err) => {
          this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          this.loading = false;
          if (err.response) {
            this.$vs.notify({
              title: "Error",
              text: err.response.data.message,
              color: "warning",
              icon: "error",
              position: "bottom-center",
            });
          } else {
            this.$vs.notify({
              title: "All Releases",
              text: "Unable to get Release",
              color: "dark",
              icon: "error",
              position: "bottom-center",
            });
          }
        });
    },
    playAudio(source) {
      this.$store.commit("audioPlay", {
        status: true,
        source,
      });
    },
  },
};
</script>
<style lang="scss">
.trackChart {
  min-height: 700px;
}
</style>
